._FCdzt {
  /* border-radius: 50%; */
  background-color: gold;
  display: flex;
  align-items: center;
  justify-content: center;
  /* overflow: hidden; */
  position: relative;
  z-index: 99;
  box-shadow: 0 4px 3px rgba(0, 0, 0, 0.356);
  margin-bottom: 0.5rem;
  margin-right: auto;
  margin-left: auto;
  margin-top: 3rem;
}

._2SsMH {
  opacity: 0;
  width: 100%;
  height: 100%;
}

._1pmC5 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
._GQrmn {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99999999;
}

._2zJnR {
  /* width: 550px;
  height: 350px; */
  width: 100%;
  height: 100%;
}

._2hdkJ {
  -o-object-fit: fill;
     object-fit: fill;
}

._1n017 {
  overflow: hidden;
  width: 100%;
  height: 100%;
}
._2B-m5 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: tomato;
  position: absolute;
  top: -15px;
  right: -15px;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}
